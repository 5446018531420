export const initRenderPricesPage = () => {
    // INITS
    renderPricePageElements();
};

function renderPricePageElements() {
    renderEmail();
    renderFeatureList();
}

function renderEmail() {
    const email = localStorage.getItem('user-email');
    const userEmailElement = document.querySelector('[data-user-email]');
    if (!email || !userEmailElement) {
        const userInfo = document.querySelector('[data-user-info]');
        if (userInfo) {
            userInfo.style.display = 'none';
        }
        return;
    }
    const activeClass = 'header-user-info__email--visible';

    userEmailElement.textContent = email;
    userEmailElement.classList.add(activeClass);
}

function renderFeatureList() {
    const device = localStorage.getItem('user-device');
    const activeClass = 'aside-feature-menu__list--visible';
    const featureList = document.querySelector(`[data-feature-list="${device}"]`);

    if (!device) {
        const androidFeatureList = document.querySelector(`[data-feature-list="android"]`);
        if (androidFeatureList) {
            androidFeatureList.classList.add(activeClass);
        }
        return;
    }

    if (featureList) {
        featureList.classList.add(activeClass);
    }
}
